.stars {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.stars svg {
  max-width: 100%;
}
.stars .StarKo svg path {
  fill: #CCCCCC !important;
}
.stars .StarHalf svg path:nth-child(2) {
  fill: #fff;
}